import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDashboardData } from '../services/commonServices';
import { Spinner } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown';

function Dashboard() {
  const [dashboardData, setDashboardData] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [filter, setFilter] = useState({
    main: "thisYear",
    patientAppFilter: "",
    donorAppFilter: ""
  });
  const [selectedFilter, setSelectedFilter] = useState({
    main: "This Year",
    patientApplications: "All",
    donorApplications: "All"
  });
  const navigate = useNavigate();
  const handleClick = (param) => {
    navigate(param)
  }

  const getList = async () => {
    setShowLoader(true)
    let data = await getDashboardData(filter);
    if (data.status === 200) {
      setDashboardData({ ...data.data.data });
      setShowLoader(false)
    }
    else {
      localStorage.removeItem('userdata');
      navigate('/login')
    }
  }
  useEffect(() => {
    getList()
  }, [filter]);
  return (
    <div>
      <div className='d-flex justify-content-end'>
        <Dropdown>
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            {selectedFilter.main}
          </Dropdown.Toggle>

          <Dropdown.Menu >
            <Dropdown.Item
              onClick={() => {
                setFilter({ ...filter, main: 'today' })
                setSelectedFilter({ ...selectedFilter, main: 'Today' })
              }}
            >Today</Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setFilter({ ...filter, main: 'thisWeek' })
                setSelectedFilter({ ...selectedFilter, main: 'This Week' })
              }}
            >This Week</Dropdown.Item>
            <Dropdown.Item onClick={() => {
              setFilter({ ...filter, main: 'thisMonth' })
              setSelectedFilter({ ...selectedFilter, main: 'This Month' })
            }}>This Month</Dropdown.Item>
            <Dropdown.Item onClick={() => {
              setFilter({ ...filter, main: 'thisYear' })
              setSelectedFilter({ ...selectedFilter, main: 'This Year' })
            }}>This Year</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
        <div style={{ flexGrow: 1 }}>
          {!showLoader ?
            <div className='dashboard'>
              <div className='cards-dashboard' onClick={() => handleClick("/patients")}>
                <div>
                  <h1>{dashboardData.totalPatients}</h1>
                  <h4>Patients</h4>
                </div>
              </div>
              <div className='cards-dashboard' onClick={() => handleClick("/transplant-centers")}>
                <div>
                  <h1>{dashboardData.totalTransplantCenters}</h1>
                  <h4>Transplant Centers</h4>
                </div>
              </div>
              <div className='cards-dashboard' onClick={() => handleClick("/dialysis-centers")}>
                <div>
                  <h1>{dashboardData.totalDialysisCenters}</h1>
                  <h4>Dialysis Centers</h4>
                </div>
              </div>
              <div className='cards-dashboard' onClick={() => handleClick("/physician-groups")}>
                <div>
                  <h1>{dashboardData.totalPhysiciansGroups}</h1>
                  <h4>Physicians Groups</h4>
                </div>
              </div>
              <div className='cards-dashboard-filter'>
                <div className='filter-temp' style={{ display: "flex", justifyContent: "space-between" }}>
                  <div><h1>{dashboardData.totalPatientApplications}</h1></div>
                  <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                      {selectedFilter.patientApplications}
                    </Dropdown.Toggle>
                    <Dropdown.Menu >
                      <Dropdown.Item
                        onClick={() => {
                          setFilter({ ...filter, patientAppFilter: '' })
                          setSelectedFilter({ ...selectedFilter, patientApplications: 'All' })
                        }}
                      >All</Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setFilter({ ...filter, patientAppFilter: 'Approved' })
                          setSelectedFilter({ ...selectedFilter, patientApplications: 'Approved' })
                        }}
                      >Approved</Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setFilter({ ...filter, patientAppFilter: 'Under-review' })
                          setSelectedFilter({ ...selectedFilter, patientApplications: 'Pending' })
                        }}
                      >Pending</Dropdown.Item>
                      <Dropdown.Item onClick={() => {
                        setFilter({ ...filter, patientAppFilter: 'Rejected' })
                        setSelectedFilter({ ...selectedFilter, patientApplications: 'Rejected' })
                      }}>Rejected</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className='filter-temp'>
                  <h4>Patient Applications</h4>
                </div>
              </div>
              <div className='cards-dashboard-filter'>
                <div className='filter-temp' style={{ display: "flex", justifyContent: "space-between" }}>
                  <div><h1>{dashboardData.totalDonorApplications}</h1></div>
                  <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                      {selectedFilter.donorApplications}
                    </Dropdown.Toggle>
                    <Dropdown.Menu >
                      <Dropdown.Item
                        onClick={() => {
                          setFilter({ ...filter, donorAppFilter: '' })
                          setSelectedFilter({ ...selectedFilter, donorApplications: 'All' })
                        }}
                      >All</Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setFilter({ ...filter, donorAppFilter: 'today' })
                          setSelectedFilter({ ...selectedFilter, donorApplications: 'Approved' })
                        }}
                      >Approved</Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setFilter({ ...filter, donorAppFilter: 'thisWeek' })
                          setSelectedFilter({ ...selectedFilter, donorApplications: 'Pending' })
                        }}
                      >Pending</Dropdown.Item>
                      <Dropdown.Item onClick={() => {
                        setFilter({ ...filter, donorAppFilter: 'thisMonth' })
                        setSelectedFilter({ ...selectedFilter, donorApplications: 'Rejected' })
                      }}>Rejected</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className='filter-temp'>
                  <h4>Donor Applications</h4>
                </div>
              </div>
            </div> :
            <Spinner animation="border" style={{ marginLeft: "50%", marginTop: "5%", width: "4rem", height: "4rem" }} size='lg' />}
        </div>
      </div>
    </div >
  )
}

export default Dashboard