import { Link, useNavigate } from 'react-router-dom';
import Logo from '../assets/img/logo.png';
import { FieldGroup, FormBuilder, Validators, FieldControl } from 'react-reactive-form';
import { resetPassword } from '../services/commonServices';
import { useSnackbar } from 'notistack'

function ResetPassword() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar()
    const loginForm = FormBuilder.group({
        password1: ["", [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).*$/)]],
        password2: ["", [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).*$/)]]
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        let { password1, password2 } = loginForm.value;
        // ------------- case for alphabet length less than 8 ----------------------------
        if (password1.length < 8 || password2.length < 8) {
            enqueueSnackbar("Password must contain minimum 8 characters.",
                { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        }
        if (loginForm.valid) {
            if (password1 === password2) {
                let data = await resetPassword({ "newPassword": password1, "confirmPassword": password2, "email": localStorage.getItem('verifiedEmail'), "token": localStorage.getItem('resetPasswordTokenData') });
                if (data.status === 200) {
                    enqueueSnackbar(data.data.message,
                        { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    navigate('/login');
                    localStorage.removeItem('resetPasswordTokenData');
                } else {
                    enqueueSnackbar(data.response.data.message,
                        { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            }
            else {
                enqueueSnackbar("New password and confirm new password must be same.",
                    { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            }
        } else {
            loginForm.markAsTouched();
        }
    }

    return (
        <div className="">
            <div className="row gx-0">
                <div className="col-md-6 d-flex align-items-center justify-content-center">
                    <div className="logo text-center" style={{ backgroundColor: "#1F6566", height: "100%", width: "100%" }}>
                        <img style={{ height: "75%", width: "75%", marginTop: "10%" }} src={Logo} alt="Logo" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="login_right_side">
                        <div className="change_password" style={{ marginTop: "15%" }}>
                            <h2 className="text-center pb-2">Set new password</h2>
                            <p className="text-center mb-5">Must be at least 8 characters.</p>
                            <FieldGroup
                                control={loginForm}
                                render={({ pristine, invalid, pending, value }) => (
                                    <form onSubmit={handleSubmit}>
                                        <FieldControl
                                            name='password1'
                                            render={({ handler, touched, hasError }) => (
                                                <div className="form-group">
                                                    <label htmlFor="password">Enter new password</label>
                                                    <div className="position-relative d-flex align-items-center">
                                                        <input type="password" className="form-control form-control1 password_input mb-0" {...handler()} id="password" />
                                                    </div>
                                                    <div className="invalid-feedback d-block errorText mb-2">
                                                        <span className='errorText'>
                                                            {(touched &&
                                                                ((hasError("required") && "Password is required")))}
                                                        </span>
                                                        <span className='errorText'>
                                                            {(touched &&
                                                                ((hasError("pattern") && "Password should contain atleast 1 number, 1 lowercase, 1 uppercase, 1 special character and must of 8 digits.")))}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                        <FieldControl
                                            name='password2'
                                            render={({ handler, touched, hasError }) => (
                                                <div className="form-group">
                                                    <label htmlFor="password">Confirm new password</label>
                                                    <div className="position-relative d-flex align-items-center">
                                                        <input type="password" className="form-control form-control1 password_input mb-0" {...handler()} id="password" />
                                                    </div>
                                                    <div className="invalid-feedback d-block errorText mb-2">
                                                        <span className='errorText'>
                                                            {(touched &&
                                                                ((hasError("required") && "Password is required")))}
                                                        </span>
                                                        <span className='errorText'>
                                                            {(touched &&
                                                                ((hasError("pattern") && "Password should contain atleast 1 number, 1 lowercase, 1 uppercase, 1 special character and must of 8 digits.")))}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                        <div className="form-group custom-input">
                                            <button type="submit" className="btn">
                                                RESET PASSWORD
                                            </button>
                                        </div>
                                        <div className="text-center mt-4">
                                            <Link to='/login' className="secondry-color">Back To Login</Link>
                                        </div>
                                    </form>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;