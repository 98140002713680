import React, { useState } from 'react'
import { enqueueSnackbar } from 'notistack';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import userTempImage from '../assets/img/avatar.png';
import editImage from '../assets/images/edit-profile.svg';
import { useNavigate } from 'react-router-dom';
import { addTransplantCenter, googlePlacesApiData } from '../services/commonServices';

function AddTransplantCenter() {
    const navigate = useNavigate();
    const [phoneRequired, setPhoneRequired] = useState(true);
    const [validPhoneNumber, setValidPhoneNumber] = useState(false);
    const [view, setView] = useState(false);
    const [showError, setShowError] = useState(false);
    const [suggestion, setSuggestion] = useState([]);
    const [input, setInput] = useState({
        name: "",
        unosId: "",
        address: "",
        email: '',
        phoneNumber: "",
        profileImage: [],
        type: "Non-Registered"
    });

    function addData(e) {
        setInput({ ...input, [e.target.name]: e.target.value });
    }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let data = [];
            data.push(event.target.files[0])
            setInput({ ...input, "profileImage": data });
            setView(true)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (input.address && input.email && input.phoneNumber) {
            let data = await addTransplantCenter(input);
            if (data?.status === 201) {
                enqueueSnackbar(data.data.message,
                    { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                setInput({})
                navigate('/transplant-centers');
            }
            else {
                enqueueSnackbar(data.response.data.message,
                    { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            }
        }
        else {
            enqueueSnackbar("Please fill all the required field!",
                { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        }
    }

    // Function for getting places data
    const sendDataToPlaces = async (e) => {
        if (e.target.value) {
            setInput({ ...input, [e.target.name]: e.target.value });
            let data = await googlePlacesApiData(e.target.value);
            if (data?.places?.length > 0) {
                if (data?.places?.length < 5) {
                    setSuggestion([...data.places]);
                }
                else {
                    setSuggestion(data.places.slice(0, 5));
                }
            }
        }
        else {
            setInput({ ...input, [e.target.name]: "" });
            setSuggestion([]);
        }
    }

    const handleSuggestionSelect = (item) => {
        setInput({ ...input, "address": item.formattedAddress });
        setSuggestion([]);
    }

    return (
        <div>
            <div className="main-content">
                <div className="">
                    <div className="row mt-2 mb-2">
                        <div className="col-lg-12">
                            <div className="page-heading">
                                <h3>Add Transplant Center</h3>
                            </div>
                        </div>
                    </div>
                    <div className='row profile-bg'>
                        <div className='col-lg-3' style={{ position: 'relative' }}>
                            {view ? (
                                <div className='profile-img1' style={{ position: 'relative' }}>
                                    <img
                                        src={
                                            input.profileImage.length > 0
                                                ? URL.createObjectURL(input.profileImage[0])
                                                : userTempImage
                                        }
                                        alt='profile'
                                        className='pro-img'
                                    />
                                    <img
                                        src={editImage}
                                        onClick={() =>
                                            document.getElementById('imageUpdate').click()
                                        }
                                        alt='profile'
                                        className='pro-edit-icon1'
                                        style={{
                                            position: 'absolute',
                                            top: '75%',
                                            left: '80%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className='profile-img1' style={{ position: 'relative' }}>
                                    <img
                                        src={
                                            input.profileImage.length > 0
                                                ? URL.createObjectURL(input.profileImage[0])
                                                : userTempImage
                                        }
                                        alt=''
                                        className='pro-img'
                                    />
                                    <img
                                        src={editImage}
                                        onClick={() =>
                                            document.getElementById('imageUpdate').click()
                                        }
                                        alt='profile'
                                        className='pro-edit-icon1'
                                        style={{
                                            position: 'absolute',
                                            top: '75%',
                                            left: '80%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                    />
                                </div>
                            )}
                            <input
                                type='file'
                                hidden='true'
                                id='imageUpdate'
                                onChange={onImageChange}
                            />
                        </div>

                        <div className="col-lg-9">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group custom-input-add">
                                            <label>Transplant Center Type </label>
                                            <select className="form-select" value={input.type} aria-label="Default select example" defaultValue={input.type} onChange={(e) => setInput({ ...input, "type": e.target.value })}>
                                                {/* <option value="Registered">Registered</option> */}
                                                <option value="Non-Registered">Non Registered</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group custom-input-add">
                                            <label >Enter Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name="name" id="name"
                                                value={input.name}
                                                onChange={addData}
                                                placeholder="Enter name" />
                                            <div className="invalid-feedback">
                                                <div >Name is required</div>
                                                <div >Blank space is not allowed</div>
                                                <div >Please enter no more than 25 characters.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group custom-input-add">
                                            <label >UNOS ID <span className="text-danger"></span></label>
                                            <input type="text" className="form-control" name="unosId" id="unosId"
                                                value={input.unosId}
                                                onChange={addData}
                                                placeholder="UNOS ID" />
                                            <div className="invalid-feedback">
                                                <div >Unos Id is required</div>
                                                <div >Blank space is not allowed</div>
                                                <div >Please enter no more than 25 characters.</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <label className="mt-1">Phone Number <span className="text-danger">*</span></label>
                                        <div className="form-group custom-input-add-phone">
                                            <PhoneInput
                                                placeholder="Enter phone number"
                                                name="phone"
                                                value={input.phoneNumber}
                                                international={true}
                                                onChange={(e) => {
                                                    if (e) {
                                                        setPhoneRequired(false)
                                                        setShowError(true)
                                                    }
                                                    else setPhoneRequired(true)
                                                    if (e && isValidPhoneNumber(e)) {
                                                        setValidPhoneNumber(true);
                                                        // --------------- changinging the state of form --------------------------
                                                        setInput({ ...input, phoneNumber: e })
                                                    }
                                                    else {
                                                        setValidPhoneNumber(false)
                                                    }
                                                    return e
                                                }}
                                            />
                                            {showError && <div style={{ marginLeft: 40, color: "red" }}>
                                                {phoneRequired && <div >Mobile number is required!</div>}
                                                {!validPhoneNumber && !phoneRequired && <div >Mobile number is invalid!</div>}
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group custom-input-add">
                                            <label className='mb-1'>Email<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" value={input.email} name="email" onChange={addData} id="email" placeholder='Enter email' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group custom-input-add">
                                            <label >Address <span className="text-danger">*</span> <span className="text-danger"></span></label>
                                            <input type="text" className="form-control" value={input.address} name="address" id="address" onChange={sendDataToPlaces} placeholder='Enter address' />
                                            <div className="invalid-feedback">
                                                <div>Address is required</div>
                                                <div>Blank space is not allowed</div>
                                                <div >Please enter no more than 25 characters.</div>
                                            </div>
                                            {suggestion.length > 0 &&
                                                <div className="form-group custom-input-add">
                                                    <select className="form-select" size={suggestion.length < 4 ? `${suggestion.length + 1}` : `5`} aria-label="size 3 select example">
                                                        {suggestion.map((suggestion, index) => (
                                                            <option style={{cursor:'pointer'}} key={index} onClick={() => handleSuggestionSelect(suggestion)}> {suggestion.formattedAddress}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group d-flex justify-content-between">
                                            <button type='submit' className="btn btn-success" >
                                                Submit
                                            </button>
                                            <button className="btn btn-danger" onClick={() => navigate('/transplant-centers')}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddTransplantCenter