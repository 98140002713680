import { useEffect, useState } from 'react';
import { getStaticContent } from '../../services/commonServices';

function Dashboard() {
    const [staticContent, setStaticContent] = useState("");

    const getList = async () => {
        let { data } = await getStaticContent();
        setStaticContent(data[0]['privacyPolicy']);
    }

    useEffect(() => {
        getList()
    }, []);

    return (
        <div className='p-2'>
            <div dangerouslySetInnerHTML={{ __html: staticContent }} />
        </div >
    )
}

export default Dashboard