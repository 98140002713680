import React, { useEffect, useState } from 'react'
import { getListData } from '../services/commonServices'
import { Table, Spinner, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment'
import Model from './Model';
import PaginationData from './Pagination';
import { Link } from 'react-router-dom';

function Donors() {
  const [donor, setDonor] = useState([]);
  const [showLoaderD, setShowLoaderD] = useState(true);
  const [showModelD, setShowModelD] = useState(false);
  const [searchValueD, setSearchValueD] = useState("");
  const [selectedDataD, setSelectedDataD] = useState("");
  const [pageD, setPageD] = useState(1);
  const [totalDataD, setTotalDataD] = useState(10);
  const headersD = ["S.No.", "Profile Pic", "Name", "Phone No.", "Email Id", "Registration Date", "Status", "Actions"];
  const getListD = async () => {
    setShowLoaderD(true);
    setDonor([]);
    let dataD = await getListData("donor", pageD, searchValueD);
    setDonor([...dataD.data[0].data]);
    if (dataD.data[0].metadata.length > 0)
      setTotalDataD(dataD.data[0].metadata[0].total)
    setShowLoaderD(false)
  }
  useEffect(() => {
    getListD()
  }, []);

  // Function for getting search data
  const getSearchDataD = async (e) => {
    setDonor([]);
    setSearchValueD(e.target.value)
    setShowLoaderD(true)
    let dataD = await getListData("donor", pageD, e.target.value);
    setDonor([...dataD.data[0].data]);
    setShowLoaderD(false)
  }

  // Function for changing the status
  const handleChangeStatusD = (status) => {
    setShowModelD(status);
    setSelectedDataD("")
    getListD()
  }

  const handleSelectD = (data) => {
    setSelectedDataD(data._id)
    setShowModelD(true);
  }

  const handlePaginationD = async (pageDNo) => {
    setPageD(pageDNo);
    setDonor([]);
    setShowLoaderD(true)
    let data = await getListData("donor", pageDNo, searchValueD);
    setDonor([...data.data[0].data]);
    setShowLoaderD(false)
  }
  return (
    <div>
      <div className='upperHeader'>
        <div><h3 style={{ textAlign: "center" }}>Donors</h3></div>
        {<div>
          <Form className="d-flex align-content-center">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              value={searchValueD}
              onChange={getSearchDataD}
              style={{ width: "250px" }}
            />
          </Form>
        </div>}
      </div>
      <div>
        {donor.length > 0 ?
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                {headersD.map((data, index) => (
                  <th key={index} className={data === "Actions" ? 'text-center' : ''}>{data}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {donor.length > 0 && donor.map((data, index) => (
                <tr key={data._id}>
                  <td>{index + 1}</td>
                  <td>
                    <Link to={`/view-detail?type=donor&id=${data._id}`} style={{ color: "black" }}>
                      <div className='image-div'>
                        <img src={data.profileImage} alt='img' />
                      </div>
                    </Link>
                  </td>
                  <td>
                    <Link to={`/view-detail?type=donor&id=${data._id}`} style={{ color: "black" }}>
                      {data?.name}
                    </Link>
                  </td>
                  <td>{data.phoneNumber}</td>
                  <td>{data.email ? data.email : "N/A"}</td>
                  <td>{moment(data.createdAt).format("LL")}</td>
                  <td>{data.status}</td>
                  <td className='text-center'>
                    <span title={data.status === "Active" ? "Block" : "Unblock"}>
                      <FontAwesomeIcon icon={faBan} style={{ color: data.status === "Active" ? "green" : "red", fontSize: "1.5em", marginLeft: "8%", cursor: "pointer" }} onClick={() => handleSelectD(data)} />
                      {selectedDataD && selectedDataD === data._id && <Model openModel={showModelD} changeValue={handleChangeStatusD} id={data._id} status={data.status} parent={'donor'} />}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table> :
          (!showLoaderD && <div style={{ marginLeft: "40%", marginTop: "100px" }}>
            <h3>No Data Available</h3>
          </div>)}
        {showLoaderD && <Spinner animation="border" style={{ marginLeft: "50%", marginTop: "5%", width: "4rem", height: "4rem" }} size='lg' />}
        {donor.length > 0 && <PaginationData page={pageD} totalData={totalDataD} getPageData={handlePaginationD} />}
      </div>
    </div>
  )
}

export default Donors