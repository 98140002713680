import React, { useEffect, useState } from 'react';
import { addSubscriptionPrice, getPriceDetails, updatePrice } from '../services/commonServices';
import { Spinner } from 'react-bootstrap'
import { useSnackbar } from 'notistack';


function Subscription() {
    const [input, setInput] = useState({ month: 0, year: 0 });
    const [inputA, setInputA] = useState({ month: 0, year: 0 });
    const [showInputM, setShowInputM] = useState(false)
    const [showInputY, setShowInputY] = useState(false);
    const [editM, setEditM] = useState(false)
    const [editY, setEditY] = useState(false);
    const [showLoaderS, setShowLoaderS] = useState(true);
    const { enqueueSnackbar } = useSnackbar();


    let adminData = JSON.parse(localStorage.getItem('userdata'));

    const handleChange = async (e) => {
        setInput({ ...input, [e.target.name]: e.target.value });
    }

    const handleSubscriptionUpdate = async (interval, price, edit, setShowInput, setEdit,
         inputField, stripeProductPriceField) => {
    let body = { interval, email: adminData.admin.email, price };
    let data;
    if (edit) {
        data = await updatePrice(body);
    } else {
        data = await addSubscriptionPrice(body);
    }
    if (data.status === 200) {
        setInput({ ...input, [inputField]: data.data.data.unit_amount });
        adminData.admin[stripeProductPriceField] = data.data.data.id;
        setInputA({ ...inputA, [inputField]: data.data.data.unit_amount });
        setShowInput(false);
        setEdit(false);
        enqueueSnackbar(`${data.data.message}`, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
    }
}

const handleCancelInput = (setShowInput, setEdit, inputField, inputAField) => {
    setShowInput(false);
    setInput({ ...input, [inputField]: inputA[inputAField] });
    setEdit(false);
}

const handleClick = async (e) => {
    const { name } = e.target;
    if (name === 'month') {
        if (input.month) {
            await handleSubscriptionUpdate(name, input.month, editM, setShowInputM, setEditM, 'month', 'stripeProductPriceMonthly');
        } else {
            handleCancelInput(setShowInputM, setEditM, 'month', 'month');
        }
    } else {
        if (input.year) {
            await handleSubscriptionUpdate(name, input.year, editY, setShowInputY, setEditY, 'year', 'stripeProductPriceYearly');
        } else {
            handleCancelInput(setShowInputY, setEditY, 'year', 'year');
        }
    }
    window.localStorage.setItem('userdata', JSON.stringify(adminData));
}

    const getData = async () => {
        if (adminData && adminData.admin && adminData.admin.hasOwnProperty("stripeProductPriceMonthly")) {
            let data = await getPriceDetails(adminData.admin.stripeProductPriceMonthly);
            if (data.status === 200) {
                setInput(prevState => ({ ...prevState, month: data.data.data.unit_amount }));
                setInputA(prevState => ({ ...prevState, month: data.data.data.unit_amount }));
            }
        }

        if (adminData && adminData.admin && adminData.admin.hasOwnProperty("stripeProductPriceYearly")) {
            let data = await getPriceDetails(adminData.admin.stripeProductPriceYearly);
            if (data.status) {
                setInput(prevState => ({ ...prevState, year: data.data.data.unit_amount }));
                setInputA(prevState => ({ ...prevState, year: data.data.data.unit_amount }));
            }
        }
        setShowLoaderS(false)
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className='subscription-main-container'>
            <h2 style={{ marginLeft: "3%" }}>Subscription Prices</h2>
            {showLoaderS && <Spinner animation="border" style={{ marginLeft: "50%", marginTop: "5%", width: "4rem", height: "4rem" }} size='lg' />}
            {!showLoaderS && <div className='subscription-container'>
                <div className="subscription-card">
                    <h2 className=' text-center text-white mt-2'>Yearly</h2>
                    <div className="d-grid gap-2 col-10 mx-auto">
                        {!showInputY && <div className='text-center text-white fs-2 fw-bold mt-5 mb-3'> {input.year ? "$" + input.year : "No price added yet"}</div>}
                        {showInputY && <input name='year' className='form-control fs-5 mt-5' style={{ minHeight: "45px" }} type='number' placeholder='Enter price in doller' value={input.year} onChange={handleChange} />}
                        {!showInputY && (input.year ? <button className="btn mt-4 fs-5 text-white" name='year' style={{ minHeight: "45px", backgroundColor: "orangered" }} onClick={() => {
                            setShowInputY(true)
                            setEditY(true)
                        }} type="button">Edit</button> :
                            <button className="btn mt-4 fs-5 text-white" style={{ minHeight: "45px", backgroundColor: "orangered" }} onClick={() => setShowInputY(true)} type="button">Add</button>)}
                        {showInputY && <button name='year' className="btn mt-4 fs-5 text-white" style={{ minHeight: "45px", backgroundColor: "orangered" }} onClick={handleClick} type="button">{input.year ? "Save" : "Cancel"}</button>}
                    </div>
                </div>
                <div className="subscription-card">
                    <h2 className=' text-center text-white mt-2'>Monthly</h2>
                    <div className="d-grid gap-2 col-10 mx-auto">
                        {!showInputM && <div className='text-center text-white fs-2 fw-bold mt-5 mb-3'>{input.month ? "$" + input.month : "No price added yet"}</div>}
                        {showInputM && <input type='number' className='form-control fs-5 mt-5' name='month' style={{ minHeight: "45px" }} placeholder='Enter price in doller' value={input.month} onChange={handleChange} />}
                        {!showInputM && (input.month ? <button className="btn mt-4 fs-5 text-white" style={{ minHeight: "45px", backgroundColor: "orangered" }} onClick={() => {
                            setShowInputM(true)
                            setEditM(true)
                        }} type="button">Edit</button> :
                            <button className="btn mt-4 fs-5 text-white" style={{ minHeight: "45px", backgroundColor: "orangered" }} onClick={() => setShowInputM(true)} type="button">Add</button>)}
                        {showInputM && <button className="btn mt-4 fs-5 text-white" style={{ minHeight: "45px", backgroundColor: "orangered" }} name='month' onClick={handleClick} type="button">{input.month ? 'Save' : "Cancel"}</button>}
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default Subscription