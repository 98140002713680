import React, { useEffect, useState } from 'react'
import { getListData } from '../services/commonServices'
import { Table, Spinner, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment'
import Model from './Model';
import PaginationData from './Pagination';
import { Link } from 'react-router-dom';

function DialysisCenters() {
  const [dialysisCenter, setDialysisCenter] = useState([]);
  const [showLoaderd, setShowLoaderd] = useState(true);
  const [showModeld, setShowModeld] = useState(false);
  const [searchValued, setSearchValued] = useState("");
  const [selectedDatad, setSelectedDatad] = useState("");
  const [paged, setPaged] = useState(1);
  const [totalDatad, setTotalDatad] = useState(10);
  const headersd = ["S.No.", "Profile Pic", "Name", "Phone No.", "Email Id", "Registration Date", "Active Applications", "Actions"];
  const getListd = async () => {
    setShowLoaderd(true);
    setDialysisCenter([]);
    let datad = await getListData("dialysisCenters", paged, searchValued);
    setDialysisCenter([...datad.data[0].data]);
    if (datad.data[0].metadata.length > 0) setTotalDatad(datad.data[0].metadata[0].total)

    setShowLoaderd(false)
  }

  useEffect(() => {
    getListd()
  }, []);

  const getSearchDatad = async (e) => {
    setDialysisCenter([]);
    setSearchValued(e.target.value)
    setShowLoaderd(true);
    let datad = await getListData("dialysisCenters", paged, e.target.value);
    setDialysisCenter([...datad.data[0].data]);
    setShowLoaderd(false)
  }

  // Function for changing the status
  const handleChangeStatusd = (status) => {
    setShowModeld(status);
    getListd()
  }

  const handleSelectd = (data) => {
    setSelectedDatad(data._id)
    setShowModeld(true);
  }

  const handlePaginationd = async (pagedNo) => {
    setPaged(pagedNo);
    setDialysisCenter([]);
    setShowLoaderd(true)
    let datad = await getListData("dialysisCenters", pagedNo, searchValued);
    setDialysisCenter([...datad.data[0].data]);
    setShowLoaderd(false)
  }
  return (
    <div>
      <div className='upperHeader'>
        <div><h3 style={{ textAlign: "center" }}>Dialysis Centers</h3></div>
        {<div>
          <Form className="d-flex align-content-center">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              value={searchValued}
              onChange={getSearchDatad}
              style={{ width: "250px" }}
            />
          </Form>
        </div>}
      </div>
      <div>
        {dialysisCenter.length > 0 ?
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                {headersd.map((data, index) => (
                  <th key={index} className={data === "Actions" ? 'text-center' : ''}>{data}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dialysisCenter.length > 0 && dialysisCenter.map((datad, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <Link to={`/view-detail?type=dialysisCenters&id=${datad._id}`} style={{ color: "black" }}>
                      <div className='image-div'>
                        <img src={datad.profileImage} alt='img' />
                      </div>
                    </Link>
                  </td>
                  <td>
                    <Link to={`/view-detail?type=dialysisCenters&id=${datad._id}`} style={{ color: "black" }}>
                      {datad.name}
                    </Link>
                  </td>
                  <td>{datad.phoneNumber}</td>
                  <td>{datad.email ? datad.email : "N/A"}</td>
                  <td>{moment(datad.createdAt).format("LL")}</td>
                  <td className='text-center'>{datad.totalNoApplications ? datad.totalNoApplications : "0"}</td>
                  <td className='text-center'>
                    <span title={datad.status === "Active" ? "Block" : "Unblock"}>
                      <FontAwesomeIcon icon={faBan} style={{ color: datad.status === "Active" ? "green" : "red", fontSize: "1.5em", marginLeft: "8%", cursor: "pointer" }} onClick={() => handleSelectd(datad)} />
                      {selectedDatad && selectedDatad === datad._id && <Model openModel={showModeld} changeValue={handleChangeStatusd} id={datad._id} status={datad.status} parent={'dialysisCenters'} />}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table> :
          (!showLoaderd && <div style={{ marginLeft: "40%", marginTop: "100px" }}>
            <h3>No Data Available</h3>
          </div>)}
        {showLoaderd && <Spinner animation="border" style={{ marginLeft: "50%", marginTop: "5%", width: "4rem", height: "4rem" }} size='lg' />}
        {dialysisCenter.length > 0 && <PaginationData page={paged} totalData={totalDatad} getPageData={handlePaginationd} />}
      </div>
    </div>
  )
}

export default DialysisCenters