import React from 'react'
import { FieldGroup, FormBuilder, Validators, FieldControl } from 'react-reactive-form';
import { changePassword } from '../services/commonServices';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

function Settings() {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate();
  const loginForm = FormBuilder.group({
    password1: ["", [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).*$/)]],
    password2: ["", [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).*$/)]],
    password3: ["", [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).*$/)]]

  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let { password1, password2, password3 } = loginForm.value;
    // ------------- case for alphabet length less than 8 ----------------------------
    if (loginForm.valid) {
      if (password3 === password2) {
        if (password3.length < 8 || password2.length < 8) {
          enqueueSnackbar("Password must contain minimum 8 characters.",
            { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        }
        else {
          let data = await changePassword({ "currentPassword": password1, "newPassword": password2, "confirmPassword": password3 })
          console.log(data);
          if (data.status === 200) {
            enqueueSnackbar(data.data.message,
              { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            navigate('/dashboard');
          } else {
            enqueueSnackbar(data.response.data.message,
              { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
          }
        }
      }
      else {
        enqueueSnackbar("New password and confirm new password must be same!",
          { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
      }

    } else {
      loginForm.markAsTouched();
    }
  }
  return (
    <div className="change_password">
      <h2 className="text-center pb-2">Change Password</h2>
      <p className="text-center">Must be at least 8 characters.</p>
      <FieldGroup
        control={loginForm}
        render={({ pristine, invalid, pending, value }) => (
          <form onSubmit={handleSubmit}>
            <FieldControl
              name='password1'
              render={({ handler, touched, hasError }) => (
                <div className="form-group base-style-div">
                  <div className="position-relative d-flex justify-content-center align-items-center">
                    <input required type="password" className="form-control  password_input mb-0" {...handler()} placeholder='Current Password' />
                  </div>
                  <div className="invalid-feedback d-block errorText">
                    <span className='errorText'>
                      {(touched &&
                        ((hasError("required") && "Current password is required")))}
                    </span>
                  </div>
                </div>
              )}
            />
            <FieldControl
              name='password2'
              render={({ handler, touched, hasError }) => (
                <div className={`form-group base-style-div ${touched && hasError('pattern') && "mb-5"}`}>
                  {/* <label htmlFor="password">Enter new password</label> */}
                  <div className="position-relative d-flex justify-content-center align-items-center">
                    <input required type="password" className="form-control  password_input mb-0" {...handler()} placeholder='Enter New Password' />
                  </div>
                  <div className="invalid-feedback d-block errorText">
                    <span className='errorText'>
                      {(touched &&
                        ((hasError("required") && "New password is required")))}
                    </span>
                    <span className='errorText'>
                      {(touched &&
                        ((hasError("pattern") && "Password should contain atleast 1 number, 1 lowercase, 1 uppercase, 1 special character and must of 8 digits.")))}
                    </span>
                  </div>
                </div>
              )}
            />
            <FieldControl
              name='password3'
              render={({ handler, touched, hasError }) => (
                <div className="form-group base-style-div">
                  {/* <label htmlFor="password">Confirm new password</label> */}
                  <div className="position-relative d-flex justify-content-center align-items-center">
                    <input required type="password" className="form-control  password_input mb-0" {...handler()} placeholder='Confirm New Password' />
                  </div>
                  <div className="invalid-feedback d-block errorText">
                    <span className='errorText'>
                      {(touched &&
                        ((hasError("required") && "Confirm password is required")))}
                    </span>
                    <span className='errorText'>
                      {(touched &&
                        ((hasError("pattern") && "Password should contain atleast 1 number, 1 lowercase, 1 uppercase, 1 special character and must of 8 digits.")))}
                    </span>
                  </div>
                </div>
              )}
            />
            <button type="submit" >
              <div className=" text-light align-top" >
                <span>CHANGE PASSWORD</span>
              </div>
            </button>
          </form>
        )}
      />
    </div>
  )
}

export default Settings