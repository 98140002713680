// DashboardLayout.js

import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Sidebar from './Sidebar';
import Header from '../Header'

const DashboardLayout = ({ children }) => {
  const [collaps, setCollaps] = useState(false);
  const handleStyle = (active) => {
    setCollaps(active);
  }
  return (
    <Container fluid>
      <Row xs={collaps && `auto`}>
        <Col sm={!collaps && 2} id='sidebar'>
          <Sidebar setStyle={handleStyle} />
        </Col>
        <Col sm={!collaps && 10} style={{ paddingRight: "0%", paddingLeft: "0%", width: collaps && "92%" }}>
          <Header />
          <div style={{margin:"2%" }}>
            {children}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardLayout;