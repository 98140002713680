import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { blockUnblock } from '../services/commonServices';

function Model({ openModel, changeValue, id, status, parent, reportType }) {
    const [parentData, setParentData] = useState('');
    const handleClose = () => { changeValue(false); }

    const handleChange = async () => {
        if (status === "Delete") {
            let data = await blockUnblock({ id, "status": "Deleted", "toUpdate": parent })
            if (data) {
                changeValue(false);
            }
        }
        else {
            let data = await blockUnblock({ id, "status": status === "Active" ? "Inactive" : "Active", "toUpdate": parent, reportType })
            if (data) {
                changeValue(false);
            }
        }
    }

    useEffect(() => {
        if (parent === 'physicianGroup') {
            setParentData('Physician Group')
        }
        else if (parent === 'transplantCenter') {
            setParentData('Transplant Center')
        }
        else if (parent === 'dialysisCenters') {
            setParentData('Dialysis Center')
        }
        else {
            let parentStr = parent.slice(0, 1).toUpperCase() + parent.slice(1, parent.length)
            setParentData(parentStr)
        }
    }, [parent])

    return (
        <Modal show={openModel} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title> Are you sure...</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {status === "Delete" ? <p>Do you want to {status.toLowerCase()} {parentData} ?</p> :
                    <p>Do you want to {status === "Active" ? "block " : "unblock"} the {parentData} ?</p>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                {status === "Delete" ? <Button variant={"danger"} onClick={handleChange}>
                    {status}
                </Button> :
                    <Button variant={status === "Active" ? "danger" : "success"} onClick={handleChange}>
                        {status === "Active" ? "Block" : "Unblock"}
                    </Button>}
            </Modal.Footer>
        </Modal>
    )
}

export default Model