import React, { useEffect, useState } from 'react'
import { enqueueSnackbar } from 'notistack';
import userTempImage from '../assets/img/avatar.png';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import { getData } from '../services/commonServices';
import moment from 'moment'
function ViewTransplantCenter() {
    const navigate = useNavigate();
    const [details, setDetails] = useState({});
    const [showLoader, setShowLoader] = useState(true);
    const { id } = useParams();
    useEffect(() => {
        getDetails(id)
    }, []);

    const getDetails = async (id) => {
        let data = await getData({ "parent": "transplantCenter", id });
        if (data.status === 200) {
            setDetails({ ...data.data.data });
            setShowLoader(false);
        }
        else {
            enqueueSnackbar("Error while getting details",
                { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        }
    }
    return (
        <Container fluid>
            {!showLoader &&
                (Object.keys(details).length > 0 ?
                    <Card className="mb-2" bg="light">
                        <Card.Header>
                            <Row>
                                <Col xs={8}>
                                    <h4 className="header-text" style={{ marginLeft: 15 }}>{details.name}</h4>
                                </Col>
                                <Col xs={4} className="text-end">
                                    <Button className="btn btn-success" onClick={() => navigate('/transplant-centers')}>
                                        Back
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Container fluid>
                                <Card bg="white" className="mb-3 p-3">
                                    <Row className="mb-3">
                                        <Col xs={4}>
                                            <div className='text-wrap' style={{ display: "flex", flexDirection: "column", borderRadius: "8px", border: "none!important", height: "80%", width: "80%", alignItems: "center" }}>
                                                <img
                                                    src={details.hasOwnProperty("profileImage") ? details.profileImage : userTempImage}
                                                    alt="img"
                                                    style={{ height: "100%", width: "100%", borderRadius: "8px", border: "none!important", objectFit: "contain" }}
                                                />
                                                {details.email}
                                            </div>
                                        </Col>
                                        <Col xs={8}>
                                            <Row className="mb-3">
                                                <Col xs={6}>
                                                    <h6>UNOS ID</h6>
                                                    <span>{details?.unosId || "N/A"}</span>
                                                </Col>
                                                <Col xs={6}>
                                                    <h6>Phone</h6>
                                                    <span>{details?.phoneNumber || "N/A"}</span>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col xs={6}>
                                                    <h6>Address</h6>
                                                    <p>{details.address ? details.address : "N/A"}</p>
                                                </Col>
                                                <Col xs={6}>
                                                    <h6>Registration Date</h6>
                                                    <p>{moment(details.createdAt).format("LL")}</p>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col xs={6}>
                                                    <h6>No. Of Applications</h6>
                                                    <p>{details?.totalNoApplications || `0`}</p>
                                                </Col>
                                                <Col xs={6}>
                                                    <h6>No. Of Pending Applications</h6>
                                                    <p>{details?.totalPendingApplications ||`0`}</p>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col xs={6}>
                                                    <h6>No. Of Approved Applications</h6>
                                                    <p>{details?.totalApprovedApplications||`0`}</p>
                                                </Col>
                                                <Col xs={6}>
                                                    <h6>No. Of Rejected Applications</h6>
                                                    <p>{details?.totalRejectedApplications ||`0`}</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            </Container>
                        </Card.Body>
                    </Card> : <div style={{ marginLeft: "40%", marginTop: "100px" }}><h3>No Data Available</h3></div>)}
            {showLoader && <Spinner animation="border" style={{ marginLeft: "50%", marginTop: "5%", width: "4rem", height: "4rem" }} size='lg' />}
        </Container>
    )
}

export default ViewTransplantCenter