import React, { useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { getStaticContent, updateStaticData } from '../services/commonServices';

function PrivacyPolicy() {
  const [staticData, setStaticData] = useState({});
  const [showEditor, setShowEditor] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const editorRef = useRef(null);

  useEffect(() => {
    getContent();
    return () => {
      removeToolbar();
    };
  }, []);

  useEffect(() => {
    if (showEditor) {
      insertToolbar();
    } else {
      removeToolbar();
    }
  }, [showEditor]);

  const getContent = async () => {
    let data = await getStaticContent();
    delete data.data[0].aboutUs;
    setStaticData(data.data[0]);
  };

  const handleSave = async () => {
    setIsLoading(true);
    let data = await updateStaticData(staticData);
    if (data && data.hasOwnProperty("data")) {
      if (data.data.hasOwnProperty("aboutUs")) {
        delete data.data.aboutUs;
      }
    }
    setStaticData(data.data);
    setShowEditor(false);
    setIsLoading(false);
  };

  const handleEditClick = () => {
    setShowEditor(true);
  };

  const editorConfigurationForPrivacy = {
    toolbar: [
      'heading',
      '|',
      'bold', 'italic',
      '|',
      'fontSize',
      '|',
      'numberedList', 'bulletedList',
      '|',
      'undo', 'redo'
    ]
  };

  const insertToolbar = () => {
    const editor = editorRef.current;
    if (editor && editor.ui && editor.ui.view && editor.ui.getEditableElement() && editor.ui.getEditableElement().parentElement) {
      const toolbarParent = editor.ui.getEditableElement().parentElement;
      toolbarParent.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
    }
  };

  const removeToolbar = () => {
    const editor = editorRef.current;
    if (editor && editor.ui.view.toolbar) {
      editor.ui.view.toolbar.element.remove();
    }
  };

  return (
    <div>
      <div className='d-flex justify-content-between mb-2'>
        <h2>Privacy Policy</h2>
        {showEditor ? (
          <button
            style={{ width: "100px", border: "1px solid whitesmoke", borderRadius: "10px", color: "white", backgroundColor: "#1F6566" }}
            onClick={handleSave}
            disabled={isLoading}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </button>
        ) : (
          <button
            style={{ width: "100px", border: "1px solid whitesmoke", borderRadius: "10px", color: "white", backgroundColor: "#1F6566" }}
            onClick={handleEditClick}
          >
            Edit
          </button>
        )}
      </div>

      <div style={{ overflowY: "auto", maxHeight: "calc(100vh - 150px)", padding: '5px' }}>
        {showEditor ? (
          <CKEditor
            editor={DecoupledEditor}
            data={staticData.privacyPolicy}
            config={editorConfigurationForPrivacy}
            onReady={(editor) => {
              editorRef.current = editor;
              insertToolbar();
            }}
            onChange={(event, editor) => {
              if (editor.getData()) {
                setStaticData({ ...staticData, "privacyPolicy": editor.getData() });
              }
            }}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: staticData.privacyPolicy }} className='staticData' />
        )}
      </div>
    </div>
  );
}

export default PrivacyPolicy;
