import React, { useEffect, useState } from 'react'
import { getListData } from '../services/commonServices'
import { Table, Spinner, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment'
import Model from './Model';
import PaginationData from './Pagination';
import { Link } from 'react-router-dom';

function Patients() {
  const [patients, setPatients] = useState([]);
  const [showLoaderP, setShowLoaderP] = useState(true);
  const [showModelP, setShowModelP] = useState(false);
  const [searchValueP, setSearchValueP] = useState("");
  const [selectedDataP, setSelectedDataP] = useState("");
  const [pageP, setPageP] = useState(1);
  const [totalDataP, setTotalDataP] = useState(10);
  const headers = ["S.No.", "Profile Pic", "Name", "Phone No.", "Email Id", "Registration Date", "Status", "Actions"];
  // function for getting list data
  const getListP = async () => {
    setShowLoaderP(true);
    setPatients([]);
    let data = await getListData("patient", pageP, searchValueP);
    setPatients([...data.data[0].data]);
    if (data.data[0].metadata.length > 0) setTotalDataP(data.data[0].metadata[0].total)
    setShowLoaderP(false)
  }

  useEffect(() => {
    getListP()
  }, []);

  // Function for getting search data
  const getSearchDataP = async (e) => {
    setPatients([]);
    setSearchValueP(e.target.value)
    setShowLoaderP(true)
    let data = await getListData("patient", pageP, e.target.value);
    setPatients([...data.data[0].data]);
    setShowLoaderP(false)
  }

  // Function for changing the status
  const handleChangeStatus = (status) => {
    setShowModelP(status);
    getListP()
  }

  const handleSelectP = (data) => {
    setSelectedDataP(data._id)
    setShowModelP(true);
  }

  const handlePaginationP = async (pagePNo) => {
    setPageP(pagePNo);
    setPatients([]);
    setShowLoaderP(true)
    let data = await getListData("patient", pagePNo, searchValueP);
    setPatients([...data.data[0].data]);
    setShowLoaderP(false)
  }

  return (
    <div>
      <div className='upperHeader'>
        <div><h3 style={{ textAlign: "center" }}>Patients</h3></div>
        {<div>
          <Form className="d-flex align-content-center">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              value={searchValueP}
              onChange={getSearchDataP}
              style={{ width: "250px" }}
            />
          </Form>
        </div>}
      </div>
      <div>
        {patients.length > 0 ?
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                {headers.map((data, index) => (
                  <th key={index} className={data === "Actions" ? 'text-center' : ''}>{data}</th>
                ))}

              </tr>
            </thead>
            <tbody>
              {patients.length > 0 && patients.map((dataP, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <Link to={`/view-detail?type=patient&id=${dataP._id}`} style={{ color: "black" }}>
                      <div className='image-div'>
                        <img src={dataP.profileImage} alt='img' />
                      </div>
                    </Link>
                  </td>
                  <td >
                    <Link to={`/view-detail?type=patient&id=${dataP._id}`} style={{ color: "black" }}>
                      {dataP?.name}
                    </Link>
                  </td>
                  <td>{dataP.phoneNumber}</td>
                  <td>{dataP.email ? dataP.email : "N/A"}</td>
                  <td>{moment(dataP.createdAt).format("LL")}</td>
                  <td>{dataP.status}</td>
                  <td className='text-center'>
                    <span title={dataP.status === "Active" ? "Block" : "Unblock"}>
                      <FontAwesomeIcon icon={faBan} style={{ color: dataP.status === "Active" ? "green" : "red", fontSize: "1.5em", marginLeft: "8%", cursor: "pointer" }} onClick={() => handleSelectP(dataP)} />
                      {selectedDataP && selectedDataP === dataP._id && <Model openModel={showModelP} changeValue={handleChangeStatus} id={dataP._id} status={dataP.status} parent={'patient'} />}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table> :
          (!showLoaderP && <div style={{ marginLeft: "40%", marginTop: "100px" }}>
            <h3>No Data Available</h3>
          </div>)}
        {showLoaderP && <Spinner animation="border" style={{ marginLeft: "50%", marginTop: "5%", width: "4rem", height: "4rem" }} size='lg' />}
        {patients.length > 0 && <PaginationData page={pageP} totalData={totalDataP} getPageData={handlePaginationP} />}
      </div>
    </div>
  )
}

export default Patients