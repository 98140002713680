import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ForgotPassword';
import OtpValidation from './components/OtpValidation';
import Dashboard from './components/Dashboard';
import DashboardLayout from '../src/components/sidebaar/Layout'
import ProtectedRoute from './utils/ProtectedRoute'
import Patients from './components/Patients';
import Doners from './components/Doners';
import DialysisCenters from './components/DialysisCenters'
import PhysicianGroups from './components/PhysicianGroups'
import Reports from './components/Reports'
import TransplantCenters from './components/TransplantCenters'
import Settings from './components/Settings'
import AboutUs from './components/AboutUs'
import PrivacyPolicy from './components/PrivacyPolicy'
import AddTransplantCenter from './components/AddTransplantCenter';
import ViewTransplantCenter from './components/ViewTransplantCenter';
import Subscription from './components/Subscription';
import ViewPatientDetail from './components/ViewPatientDetail';

import PrivacyPolicyWeb from './components/webview/PrivacyPolicy';
import AboutUsWeb from './components/webview/AboutUs';

function App() {
  let adminData = JSON.parse(localStorage.getItem('userdata'));
  return (
    <div className="App">
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/otp-validation' element={<OtpValidation />} />
        <Route path='/webview/privacy-policy' element={<PrivacyPolicyWeb />} />
        <Route path='/webview/about-us' element={<AboutUsWeb />} />
        <Route path='/dashboard' element={<ProtectedRoute ><DashboardLayout><Dashboard /></DashboardLayout></ProtectedRoute>} />
        <Route path='/patients' element={<ProtectedRoute ><DashboardLayout><Patients /></DashboardLayout></ProtectedRoute>} />
        <Route path='/donors' element={<ProtectedRoute ><DashboardLayout><Doners /></DashboardLayout></ProtectedRoute>} />
        <Route path='/dialysis-centers' element={<ProtectedRoute ><DashboardLayout><DialysisCenters /></DashboardLayout></ProtectedRoute>} />
        <Route path='/physician-groups' element={<ProtectedRoute ><DashboardLayout><PhysicianGroups /></DashboardLayout></ProtectedRoute>} />
        <Route path='/reports' element={<ProtectedRoute ><DashboardLayout><Reports /></DashboardLayout></ProtectedRoute>} />
        <Route path='/transplant-centers' element={<ProtectedRoute ><DashboardLayout><TransplantCenters /></DashboardLayout></ProtectedRoute>} />
        <Route path='/settings' element={<ProtectedRoute ><DashboardLayout><Settings /></DashboardLayout></ProtectedRoute>} />
        <Route path='/about-us' element={<ProtectedRoute ><DashboardLayout><AboutUs /></DashboardLayout></ProtectedRoute>} />
        <Route path='/privacy-policy' element={<ProtectedRoute ><DashboardLayout><PrivacyPolicy /></DashboardLayout></ProtectedRoute>} />
        <Route path='/add-transplant-center' element={<ProtectedRoute ><DashboardLayout><AddTransplantCenter /></DashboardLayout></ProtectedRoute>} />
        <Route path='/view-detail' element={<ProtectedRoute ><DashboardLayout><ViewPatientDetail /></DashboardLayout></ProtectedRoute>} />
        <Route path='/view-transplant-center/:id' element={<ProtectedRoute ><DashboardLayout><ViewTransplantCenter /></DashboardLayout></ProtectedRoute>} />
        <Route path='/subscription-pricing' element={<ProtectedRoute ><DashboardLayout><Subscription /></DashboardLayout></ProtectedRoute>} />
        <Route path='*' element={<Navigate to={adminData?.hasOwnProperty("token") ? '/dashboard' : '/login'} />} />
      </Routes>
    </div>
  );
}

export default App;
