import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGauge,
  faUsersRectangle,
  faHandHoldingMedical,
  faHospitalUser,
  faUserShield,
  faNotesMedical,
  faHospital,
  faInfoCircle,
  faShieldHalved,
} from '@fortawesome/free-solid-svg-icons';
import Logo from '../../assets/img/logo2.png';

const SidebarItem = ({ to, icon, label, toggle }) => {
  const path = useLocation();
  const navigate = useNavigate();
  const isActive = path.pathname === to;

  return (
    <Nav.Item
      style={{ cursor: 'pointer' }}
      className={`${!toggle ? 'sidebar-content' : 'sidebar-content-clicked'} ${isActive && 'selected-tab'}`}
      onClick={() => navigate(to)}
    >
      <FontAwesomeIcon
        icon={icon}
        style={{
          color: isActive ? '#D35400' : 'white',
          fontSize: '1.7em',
          marginLeft: !toggle && '8%',
          cursor: 'pointer',
        }}
      />
      {!toggle && (
        <Nav.Link
          as={Link}
          to={to}
          active={window.location.pathname === to}
          style={{
            color: isActive ? 'black' : 'white',
            fontWeight: isActive && 'bolder',
          }}
        >
          {label}
        </Nav.Link>
      )}
    </Nav.Item>
  );
};

const Sidebar = ({ setStyle }) => {
  const [toggle, setToggle] = useState(false);

  const menuItems = [
    { to: '/dashboard', icon: faGauge, label: 'Dashboard' },
    { to: '/patients', icon: faUsersRectangle, label: 'Patients' },
    { to: '/donors', icon: faHandHoldingMedical, label: 'Donors' },
    { to: '/dialysis-centers', icon: faHospitalUser, label: 'Dialysis Centers' },
    { to: '/physician-groups', icon: faUserShield, label: 'Physician Groups' },
    { to: '/reports', icon: faNotesMedical, label: 'Reports' },
    { to: '/transplant-centers', icon: faHospital, label: 'Transplant Centers' },
    { to: '/about-us', icon: faInfoCircle, label: 'About Us' },
    { to: '/privacy-policy', icon: faShieldHalved, label: 'Privacy Policy' },
  ];

  return (
    <Nav className='flex-column' style={{ height: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        {!toggle && (
          <img
            src={Logo}
            style={{ width: '150px', objectFit: 'contain' }}
            alt='logo'
          />
        )}
        <div style={{ width: toggle && '80px' }}>
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '1.5em',
              cursor: 'pointer',
            }}
            onClick={() => {
              setToggle(!toggle);
              setStyle(!toggle);
            }}
          >
            ☰
          </span>
        </div>
      </div>
      {menuItems.map(item => (
        <SidebarItem key={item.to} {...item} toggle={toggle} />
      ))}
    </Nav>
  );
};

export default Sidebar;
