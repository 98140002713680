import React, { useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { getStaticContent, updateStaticData } from '../services/commonServices';
import { useSnackbar } from 'notistack';

function AboutUs() {
  const [staticDataAbout, setStaticDataAbout] = useState({});
  const [showEditorAbout, setShowEditorAbout] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const editorRefAbout = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getContent1();
    return () => {
      removeToolbarAbout();
    };
  }, []);

  useEffect(() => {
    if (showEditorAbout) {
      insertToolbarAbout();
    } else {
      removeToolbarAbout();
    }
  }, [showEditorAbout]);

  const getContent1 = async () => {
    let data = await getStaticContent();
    delete data.data[0].privacyPolicy
    setStaticDataAbout(data.data[0]);
  }


  const handleSave1 = async () => {
    setIsLoading(true);
    let data = await updateStaticData(staticDataAbout);
    if (data && data.hasOwnProperty("data")) {
      if (data.data.hasOwnProperty("privacyPolicy")) {
        delete data.data.privacyPolicy
        setStaticDataAbout(data.data);
        setShowEditorAbout(false)
      }
    } else {
      enqueueSnackbar("Please remove whitespace!", {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'top' }
      });
    }
    setIsLoading(false);
  }

  const handleEditClick = () => {
    setShowEditorAbout(true);
  };

  const editorConfiguration = {
    toolbar: [
      'heading',
      '|',
      'bold', 'italic',
      '|',
      'fontSize',
      '|',
      'numberedList', 'bulletedList',
      '|',
      'undo', 'redo'
    ]
  };

  const insertToolbarAbout = () => {
    const editor = editorRefAbout.current;
    if (editor && editor.ui && editor.ui.view && editor.ui.getEditableElement() && editor.ui.getEditableElement().parentElement) {
      const toolbarParent = editor.ui.getEditableElement().parentElement;
      toolbarParent.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
    }
  };

  const removeToolbarAbout = () => {
    const editor = editorRefAbout.current;
    if (editor && editor.ui.view.toolbar) {
      editor.ui.view.toolbar.element.remove();
    }
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <div className='d-flex justify-content-between mb-2'>
        <h2>About Us</h2>
        {showEditorAbout ? (
          <button
            style={{ width: "100px", border: "1px solid whitesmoke", borderRadius: "10px", color: "white", backgroundColor: "#1F6566" }}
            onClick={handleSave1}
            disabled={isLoading}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </button>
        ) : (
          <button
            style={{ width: "100px", border: "1px solid whitesmoke", borderRadius: "10px", color: "white", backgroundColor: "#1F6566" }}
            onClick={handleEditClick}
          >
            Edit
          </button>
        )}
      </div>
      <div style={{ overflowY: "auto", maxHeight: "calc(100vh - 150px)", padding: '5px' }}>
        {showEditorAbout ? (
          <CKEditor
            editor={DecoupledEditor}
            data={staticDataAbout.aboutUs}
            config={editorConfiguration}
            onReady={(editor) => {
              editorRefAbout.current = editor;
              insertToolbarAbout();
            }}
            onChange={(event, editor) => {
              if (editor.getData()) {
                setStaticDataAbout({ ...staticDataAbout, "aboutUs": editor.getData() });
              }
            }}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: staticDataAbout.aboutUs }} className='staticDataAbout' />
        )}
      </div>
    </div>
  );
}

export default AboutUs;

