import axios from 'axios';

export const login = (body) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/login`, body)
        .then((res) => res.data)
        .catch((err) => err)
}

export const forgotPassword = (body) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/forgot-password`, body)
        .then((res) => {
            localStorage.setItem("verifiedEmail", body.email)
            return res
        })
        .catch((err) => err)
}

export const validateOtp = async (body) => {
    try {
        let res = await axios.post(`${process.env.REACT_APP_API_URL}/verify-code`, body)
        return res
    } catch (error) {
        return error
    }
}

export const resetPassword = async (body) => {
    try {
        let res = await axios.post(`${process.env.REACT_APP_API_URL}/reset-password`, body)
        return res
    } catch (error) {
        return error
    }
}

export const changePassword = async (body) => {
    try {
        let res = await axios.post(`${process.env.REACT_APP_API_URL}/change-password`, body,
            { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem('userdata')).token}` } }
        );
        return res;
    } catch (error) {
        return error
    }
}

export const getListData = async (type, page, search) => {
    try {
        let res = await axios.get(`${process.env.REACT_APP_API_URL}/getLists?type=${type}&page=${page}&searchQuery=${search}`,
            { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem('userdata')).token}` } }
        );
        return res.status === 200 && res.data
    } catch (error) {
        return error
    }
}

export const getDashboardData = async (filter) => {
    try {
        let url = `${process.env.REACT_APP_API_URL}/dashboard?filterBy=${filter.main}`
        if (filter.patientAppFilter) {
            url = url + `&patientAppFilter=${filter.patientAppFilter}`
        }
        if (filter.donorAppFilter) {
            url = url + `&donorAppFilter=${filter.donorAppFilter}`
        }
        let res = await axios.get(url, { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem('userdata')).token}` } });
        return res
    } catch (error) {
        return error
    }
}

export const searchQuery = async (data, type) => {
    try {
        let res = await axios.get(`${process.env.REACT_APP_API_URL}/search?type=${type}&search=${data}`,
            { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem('userdata')).token}` } }
        )
        return res.status === 200 && res.data
    } catch (error) {
        return error
    }
}

export const blockUnblock = async (body) => {
    try {
        let res = await axios.patch(`${process.env.REACT_APP_API_URL}/updateStatus`, body,
            { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem('userdata')).token}` } }
        );
        return res.status === 200 && res.data
    } catch (error) {
        return error
    }
}

export const getData = async (body) => {
    try {
        let res = await axios.get(`${process.env.REACT_APP_API_URL}/singleList?type=${body.parent}&id=${body.id}`,
            { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem('userdata')).token}` } }
        );
        return res
    } catch (error) {
        return error
    }
}

export const googlePlacesApiData = async (input) => {
    try {
        let res = await axios.post(`https://places.googleapis.com/v1/places:searchText?key=${process.env.REACT_APP_GOOGLE_PLACES_KEY}`,
            { "textQuery": `${input}` }, {
            headers: {
                'Content-Type': 'application/json',
                'X-Goog-FieldMask': 'places.displayName,places.formattedAddress,places.priceLevel'
            }
        });
        return res.data
    }
    catch (error) {
        console.log(error);
        return error
    }
}

export const addTransplantCenter = async (body) => {
    try {
        // Logic for custom binding in form-data
        const formData = new FormData();
        for (const key in body) {
            if (key === 'profileImage') {
                formData.append(key, body[key][0])
            }
            else {
                if (key && body[key]) {
                    formData.append(key, body[key]);
                }
            }
        }
        let res = await axios.post(`${process.env.REACT_APP_API_URL}/addTransplant`, formData, { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem('userdata')).token}`, "Content-Type": "multipart/form-data" } });
        return res
    } catch (error) {
        return error
    }
}

export const getStaticContent = async () => {
    try {
        let res = await axios.get(`${process.env.REACT_APP_API_URL}/content`);
        return res.status === 200 && res.data
    } catch (error) {
        return error
    }
}

export const updateStaticData = async (body) => {
    body.id = body._id
    try {
        let res = await axios.patch(`${process.env.REACT_APP_API_URL}/contentUpdate`, body,
            { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem('userdata')).token}` } }
        );
        return res.status === 200 && res.data
    } catch (error) {
        return error
    }
}

export const addSubscriptionPrice = async (body) => {
    try {
        let res = await axios.post(`${process.env.REACT_APP_API_URL}/create-price`, body,
            { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem('userdata')).token}` } }
        );
        return res
    } catch (error) {
        return error
    }
}

export const getPriceDetails = async (priceId) => {
    try {
        let res = await axios.get(`${process.env.REACT_APP_API_URL}/get-price-details?priceId=${priceId}`,
            { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem('userdata')).token}` } });
        return res;
    } catch (error) {
        return error
    }
}

export const updatePrice = async (body) => {
    try {
        let res = await axios.patch(`${process.env.REACT_APP_API_URL}/update-price`, body,
            { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem('userdata')).token}` } }
        );
        return res
    } catch (error) {
        return error
    }
}

export const getNotification = async () => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/get-notification`,
            { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem('userdata')).token}` } }
        );
    } catch (error) {
        console.log("🚀 ~ getNotification ~ error:", error)
        throw error
    }
}