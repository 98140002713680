import React, { useEffect, useState } from 'react'
import { getListData } from '../services/commonServices'
import { Table, Spinner, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faBan, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment'
import Model from './Model';
import { useNavigate } from 'react-router-dom';
import PaginationData from './Pagination';

function TransplantCenters() {
  const [transplantCenters, setTransplantCenters] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [selectedData, setSelectedData] = useState("");
  const [showModel, setShowModel] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState("");
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(10);
  const headers = ["S.No.", "Name", "UNOS ID", "Phone No.", "Email ID", "Registration Date", "Subscription Type", "Status", "Actions"];
  const navigate = useNavigate();
  const getList = async () => {
    setShowLoader(true);
    setTransplantCenters([]);
    let data = await getListData("transplantCenter", page, searchValue);
    setTransplantCenters([...data.data[0].data]);
    if (data.data[0].metadata.length > 0) setTotalData(data.data[0].metadata[0].total)

    setShowLoader(false)
  }
  useEffect(() => {
    getList()
  }, []);

  const getSearchData = async (e) => {
    setTransplantCenters([]);
    setSearchValue(e.target.value)
    setShowLoader(true)
    let data = await getListData("transplantCenter", page, e.target.value);
    setTransplantCenters([...data.data[0].data]);
    setShowLoader(false)
  }

  const handleSelect = (data, status) => {
    if (status === "Delete") setDeleteStatus(status);
    else setDeleteStatus("")
    setSelectedData(data._id);
    setShowModel(true);
  }

  // Function for changing the status
  const handleChangeStatus = (status) => {
    setShowModel(status);
    setSelectedData("");
    setDeleteStatus("")
    getList()
  }

  const handlePagination = async (pageNo) => {
    setPage(pageNo);
    setTransplantCenters([]);
    setShowLoader(true)
    let data = await getListData("transplantCenter", pageNo, searchValue);
    setTransplantCenters([...data.data[0].data]);
    setShowLoader(false)
  }

  return (
    <div>
      <div className='upperHeader'>
        <div><h3 style={{ textAlign: "center" }}>Transplant Centers</h3></div>
        {<div>
          <Form className="d-flex align-content-center">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              value={searchValue}
              onChange={getSearchData}
              style={{ width: "250px" }}
            />
            <span title='Add New Transplant Center'>
              <FontAwesomeIcon icon={faPlusCircle} style={{ color: "black", fontSize: "2.5em", cursor: "pointer", marginLeft: 2 }} onClick={() => navigate('/add-transplant-center')} />
            </span>
          </Form>
        </div>}
      </div>
      <div>
        {transplantCenters.length > 0 ?
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                {headers.map((data, index) => (
                  <th key={index} className={data === "Actions" ? 'text-center' : ''}>{data}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {transplantCenters.length > 0 && transplantCenters.map((data, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{data?.name||"N/A"}</td>
                  <td>{data?.unosId ? data?.unosId : `N/A`}</td>
                  <td>{data?.phoneNumber||"N/A"}</td>
                  <td>{data.email }</td>
                  <td>{moment(data.createdAt).format("LL") }</td>
                  <td>{data?.subscription?.subscription_type||"N/A"}</td>
                  <td>{data?.status}</td>
                  <td className='text-center'>
                    <span title={data.status === "Active" ? "Block" : "Unblock"}><FontAwesomeIcon icon={faBan} style={{ color: data.status === "Active" ? "green" : "red", fontSize: "110%", cursor: "pointer" }} onClick={() => handleSelect(data)} /></span>
                    {selectedData && selectedData === data._id && <Model openModel={showModel} changeValue={handleChangeStatus} id={data._id} status={deleteStatus ? deleteStatus : data.status} parent={'transplantCenter'} />}
                    <span className="mx-1" title='Delete Transplant Center'><FontAwesomeIcon icon={faTrash} style={{ color: "red", fontSize: "110%", marginLeft: "5%", cursor: "pointer" }} onClick={() => handleSelect(data, "Delete")} /></span>
                    <span title='Detail View'>
                      <FontAwesomeIcon icon={faEye} style={{ color: "black", fontSize: "110%", marginLeft: "5%", cursor: "pointer" }} onClick={() => navigate(`/view-transplant-center/${data._id}`)} />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table> :
          (!showLoader && <div style={{ marginLeft: "40%", marginTop: "100px" }}>
            <h3>No Data Available</h3>
          </div>)}
        {showLoader && <Spinner animation="border" style={{ marginLeft: "50%", marginTop: "5%", width: "4rem", height: "4rem" }} size='lg' />}
        {transplantCenters.length > 0 && <PaginationData page={page} totalData={totalData} getPageData={handlePagination} />}
      </div>
    </div>
  )
}
export default TransplantCenters