import React, { useEffect, useState } from 'react';
import { Navbar, Container, Dropdown } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faSignOutAlt, faBell } from '@fortawesome/free-solid-svg-icons';
import notificationIcon from '../helpers/notification.svg'
import moment from 'moment'
import { getNotification } from '../services/commonServices';
import { Spinner } from 'react-bootstrap';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [loading, setLoading] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogOut = () => {
    window.localStorage.removeItem("userdata");
    navigate('/login');
  };

  const handleNotificationClick = async () => {
    setNotificationOpen(!notificationOpen);
    if (!notificationOpen) {
      setLoading(true);
      try {
        const res = await getNotification();
        setNotificationList(res.data.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (notificationOpen) {
      setNotificationOpen(false);
    }
  }, [location.pathname]);

  return (
    <Navbar bg="light" variant="light" style={{ borderBottom: "1px solid whitesmoke", height: "80px" }}>
      <Container>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end align-content-center">
          <div style={{ display: "flex", justifyContent: "space-between", width: "25%", marginRight: "1rem", alignItems: "center" }}>
            <Navbar.Brand as={Link} to="/dashboard">Hey, {JSON.parse(localStorage.getItem('userdata'))?.admin?.name}</Navbar.Brand>
            <Navbar.Brand style={{ cursor: "pointer", color: notificationOpen ? "#1f6566" : "" }} onClick={handleNotificationClick}>
              <FontAwesomeIcon icon={faBell} />
            </Navbar.Brand>
            <Dropdown align='end' show={dropdownOpen} onToggle={toggleDropdown} title='Settings'>
              <Dropdown.Toggle variant='none' id="dropdown-icon">
                <FontAwesomeIcon icon={faGear} style={{ color: "black", fontSize: "1.5em", cursor: "pointer" }} onClick={() => setDropdownOpen(!dropdownOpen)} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => navigate("/settings")}>Change Password</Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/subscription-pricing")}>Subscription Pricing</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div style={{ textAlign: "center" }} onClick={handleLogOut} title='Logout'>
              <FontAwesomeIcon icon={faSignOutAlt} style={{ color: "black", fontSize: "1.5em", cursor: "pointer" }} />
            </div>
          </div>
        </Navbar.Collapse>
        {notificationOpen && (
          <div
            style={{
              borderRadius: "8px",
              position: 'absolute',
              top: '60px',
              right: '10px',
              width: '300px',
              height: '400px',
              border: '1px solid #ccc',
              backgroundColor: 'white',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              zIndex: 1000,
              overflowY: 'auto'
            }}
          >
            <div
              style={{
                padding: '10px',
                borderBottom: '1px solid #ccc',
                backgroundColor: '#f7f7f7',
                fontWeight: "bold",
                position: 'sticky',
                top: 0,
                zIndex: 10
              }}
            >
              Notifications
            </div>
            <div style={{ padding: '10px' }}>
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Spinner animation="border" />
                </div>
              ) : notificationList.length === 0 ? (
                <div style={{ textAlign: 'center', padding: '10px' }}>
                  Notification not found
                </div>
              ) : (
                notificationList.map((notification, index) => (
                  <div key={index} className='d-flex align-items-start' style={{ padding: '10px', borderBottom: '1px solid #eee' }}>
                    <img src={notificationIcon} alt='logo' style={{ marginRight: '10px' }} />
                    <div>
                      <p>{notification?.description}</p>
                      <p className='text-muted' style={{ fontSize: '0.8em' }}>{moment(notification?.createdAt).fromNow()}</p>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        )}
      </Container>
    </Navbar>
  );
};

export default Header;