import React, { useEffect, useState } from 'react'
import { getListData } from '../services/commonServices'
import { Table, Spinner, Form, Modal, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faEye } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment'
import PaginationData from './Pagination';
import Model from './Model';
import { Link } from 'react-router-dom';

function Reports() {
  const [reports, setreports] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [selectedData, setSelectedData] = useState("");
  const [showModel, setShowModel] = useState(false);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(10);
  const [show, setShow] = useState(false);
  const [selectedText, setSelectedText] = useState('');

  const handleShow = (text) => {
    setSelectedText(text);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const headers = ["Sr No", "Profile Pic", "Name", "Phone No.", "Email Id", "Reported By", "Reason", "Date of Report", "Actions"];

  const getList = async () => {
    setShowLoader(true);
    setreports([]);
    let data = await getListData("report", page, searchValue);
    if (data && data.data.length > 0) {
      setreports([...data.data[0].data]);
      if (data.data[0].metadata.length > 0) setTotalData(data.data[0].metadata[0].total)
    }
    setShowLoader(false)
  }
  useEffect(() => {
    getList()
  }, []);

  const getSearchData = async (e) => {
    setreports([]);
    setSearchValue(e.target.value)
    setShowLoader(true)
    let data = await getListData("report", page, e.target.value);
    setreports([...data.data[0].data]);
    setShowLoader(false)
  }

  const handlePagination = async (pageNo) => {
    setPage(pageNo);
    setreports([]);
    setShowLoader(true)
    let data = await getListData("report", pageNo, searchValue);
    setreports([...data.data[0].data]);
    setShowLoader(false)
  }

  const handleSelect = (data) => {
    setSelectedData(data._id)
    setShowModel(true);
  }

  const handleChangeStatus = (status) => {
    setShowModel(status);
    getList()
  }

  let parmsType = {
    Recipient: "patient",
    Donor: "donor",
    Dialysis: "dialysisCenters",
    Physician: "physicianGroup"
  }

  return (
    <div>
      <div className='upperHeader'>
        <div><h3 style={{ textAlign: "center" }}>Reports</h3></div>
        {<div>
          <Form className="d-flex align-content-center">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              value={searchValue}
              onChange={getSearchData}
              style={{ width: "250px" }}
            />
          </Form>
        </div>}
      </div>
      <div>
        {reports.length > 0 ?
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                {headers.map((data, index) => (
                  <th key={index}>{data}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {reports.length > 0 && reports.map((data, index) => (
                <tr key={data._id}>
                  <td>{index + 1}</td>
                  <td>
                    <Link to={`/view-detail?type=${parmsType[data.type]}&id=${data.userID}`} style={{ color: "black" }}>
                      <div className='image-div'>
                        <img src={data.profileImage} alt='img' />
                      </div>
                    </Link>
                  </td>
                  <td>{data?.name}</td>
                  <td>{data?.phoneNumber}</td>
                  <td>{data?.email ? data.email : "N/A"}</td>
                  <td>{data?.transplantName || "N/A"}</td>
                  <td>{
                    <>
                      {data?.reason.substring(0, 25)}
                      <FontAwesomeIcon icon={faEye} onClick={() => handleShow(data?.reason)} style={{ color: "black", fontSize: "110%", marginLeft: "10%", cursor: "pointer" }} />
                    </>
                    || "N/A"}</td>
                  <td>{moment(data.createdAt).format("LL")}</td>
                  <td>
                    <span title={data.status === "Active" ? "Block" : "Unblock"}><FontAwesomeIcon icon={faBan} style={{ color: data.status === "Active" ? "green" : "red", fontSize: "1.5em", marginLeft: "8%", cursor: "pointer" }} onClick={() => handleSelect(data)} /></span>
                    {selectedData && selectedData === data._id && <Model openModel={showModel} changeValue={handleChangeStatus} id={data.userID} status={data.status} parent={'report'} reportType={data.type} />}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table> :
          (!showLoader && <div style={{ marginLeft: "40%", marginTop: "100px" }}>
            <h3>No Data Available</h3>
          </div>)}
        {showLoader && <Spinner animation="border" style={{ marginLeft: "50%", marginTop: "5%", width: "4rem", height: "4rem" }} size='lg' />}
        {reports.length > 0 && <PaginationData page={page} totalData={totalData} getPageData={handlePagination} />}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">{selectedText}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Reports