import React, { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../assets/img/logo.png';
import { FieldGroup, FormBuilder, Validators, FieldControl } from 'react-reactive-form';
import { validateOtp, forgotPassword } from '../services/commonServices';
import { useSnackbar } from 'notistack';
import '../assets/css/style.css';

function OtpValidation() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const loginForm = FormBuilder.group({
        number1: ["", [Validators.required]],
        number2: ["", [Validators.required]],
        number3: ["", [Validators.required]],
        number4: ["", [Validators.required]]
    });

    const inputRefs = useRef([]);
    const handleKeyDown = (event, index) => {
        if (event.key === 'Backspace' && event.target.value === '') {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        } else if (event.target.value.length >= 1 && index < 3) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleChange = (event) => {
        const input = event.target;
        if (!/^\d$/.test(input.value)) {
            input.value = '';
        }
    };

    // ------------ Function for resend the verification code -----------------------------------------------------
    let handleClick = async () => {
        let data = await forgotPassword({ email: localStorage.getItem('verifiedEmail') });
        if (data.status === 200 && data.data.message) {
            enqueueSnackbar("Verification code resent successfully.",
                { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        }
        else {
            enqueueSnackbar(data.response.data.message,
                { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } }
            );
        }
    };

    // ------------ Function for submitting form data -----------------------------------------------------
    const handleSubmit = async (e) => {
        e.preventDefault();
        let { number1, number2, number3, number4 } = loginForm.value;
        if (!number1 || !number2 || !number3 || !number4) {
            enqueueSnackbar("Verification code is required", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        }

        if (loginForm.valid) {
            let verificationCode = number1 + number2 + number3 + number4;
            let data = await validateOtp({ "verificationCode": parseInt(verificationCode), "email": localStorage.getItem('verifiedEmail') });
            if (data.status === 200) {
                enqueueSnackbar(data.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                localStorage.setItem("resetPasswordTokenData", data.data.token);
                localStorage.removeItem('verifiedEmail');
                navigate('/reset-password');
            }
            else {
                enqueueSnackbar(data.response.data.message,
                    { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            }
        }
        else {
            loginForm.markAsTouched();
        }
    };

    return (
        <div className="">
            <div className="row gx-0">
                <div className="col-md-6 col-sm-0 d-flex align-items-center justify-content-center">
                    <div className="logo text-center" style={{ backgroundColor: "#1F6566", height: "100%", width: "100%" }}>
                        <img style={{ height: "75%", width: "75%", marginTop: "10%" }} src={Logo} alt="Logo" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="login_right_side">
                        <div className="change_password" style={{ marginTop: "15%" }}>
                            <h2 className=" text-center pb-1">Password reset?</h2>
                            <p className=" text-center mb-5">We sent a verification code to <strong>{localStorage.getItem("verifiedEmail")}</strong></p>

                            <FieldGroup
                                control={loginForm}
                                render={({ pristine, invalid, pending, value }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <ul className="otp-msg mt-3">
                                                {[...Array(4)].map((_, index) => (
                                                    <li key={index}>
                                                        <FieldControl
                                                            name={`number${index + 1}`}
                                                            render={({ handler, touched, hasError }) => (
                                                                <div>
                                                                    <input
                                                                        ref={ref => inputRefs.current[index] = ref}
                                                                        type="text"
                                                                        maxLength="1"
                                                                        onKeyDown={(event) => handleKeyDown(event, index)}
                                                                        onChange={(event) => handleChange(event)}
                                                                        className="form-control form-control2 mb-0"
                                                                        {...handler()}
                                                                        id={`one${index + 1}`}
                                                                    />
                                                                </div>
                                                            )}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="form-group custom-input">
                                            <button type="submit" className="btn-style-one btn btn_default text-center">
                                                <span>Continue</span>
                                            </button>
                                        </div>
                                        <p className="text-center  mt-4">Don't receive the email
                                            <span style={{ cursor: "pointer" }} className="secondry-color text-decoration-none fw-bold resend-link" onClick={handleClick} > Click to resend</span></p>
                                        <div className="text-center mt-4">
                                            <Link to='/login' className="secondry-color">Back to Login</Link>
                                        </div>
                                    </form>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OtpValidation;
