import { Link, useNavigate } from 'react-router-dom';
import Logoy from '../assets/img/logo.png';
import { FieldGroup, FormBuilder, Validators, FieldControl } from 'react-reactive-form';
import { login } from '../services/commonServices';
import { useSnackbar } from 'notistack'

function Login() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const loginForm = FormBuilder.group({
        email: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required, Validators.minLength(8)]]
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (loginForm.valid) {
            let data = await login({ ...loginForm.value });
            if (data.token) {
                window.localStorage.setItem("userdata", JSON.stringify({ token: data.token, admin: data.data }))
                navigate('/dashboard');
            }
            else {
                enqueueSnackbar(data.response.data.message,
                    { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            }
        } else {
            enqueueSnackbar("please fill all the required fields correctly!",
                { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            loginForm.markAsTouched();
        }
    }

    return (
        <div>
            <div className="row gx-0">
                <div className="col-md-6 d-flex align-items-center justify-content-center">
                    <div className="logo text-center" style={{ backgroundColor: "#1F6566", height: "100%", width: "100%" }}>
                        <img style={{ height: "75%", width: "75%", marginTop: "10%" }} src={Logoy} alt="Logo" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="login_right_side">
                        <div className="change_password" style={{ marginTop: "15%" }}>
                            <h2 className="text-center pb-5">Login</h2>
                            <FieldGroup
                                control={loginForm}
                                render={({ pristine, invalid, pending, value }) => (
                                    <form onSubmit={handleSubmit}>
                                        <FieldControl
                                            name='email'
                                            render={({ handler, touched, hasError }) => (
                                                <div className="form-group">
                                                    <label htmlFor="email">Email</label>
                                                    <input type="email" className="form-control form-control1 mb-0" {...handler()} id="email" />
                                                    <div className='invalid-feedback d-block errorText mb-2'>
                                                        <span className='errorText'>
                                                            {(touched &&
                                                                ((hasError("required") && "Email is required") ||
                                                                    (hasError("email") &&
                                                                        "Please enter a valid email")))}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                        <FieldControl
                                            name='password'
                                            render={({ handler, touched, hasError }) => (
                                                <div className="form-group">
                                                    <label htmlFor="password">Password</label>
                                                    <div className="position-relative d-flex align-items-center">
                                                        <input type="password" className="form-control form-control1 password_input mb-0" {...handler()} id="password" />
                                                    </div>
                                                    <div className="invalid-feedback d-block errorText mb-2">
                                                        <span className='errorText'>
                                                            {(touched &&
                                                                ((hasError("required") && "Password is required")))}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                        <div className="form-group d-flex align-items-center">
                                            <Link to='/forgot-password' className="secondry-color">Forgot password?</Link>
                                        </div>
                                        <div className="form-group custom-input">
                                            <button type="submit" className="btn-style-one btn btn_default text-center">
                                                <span>Submit</span>
                                            </button>
                                        </div>
                                    </form>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;