import React, { useEffect, useState } from 'react'
import { Pagination } from 'semantic-ui-react'

const PaginationData = ({ page, totalData, getPageData }) => {
    const [totalList, setTotalList] = useState(0);

    const customLogic = (value) => {
        if (value > 10) {
            let remainder = value % 10;
            let items = parseInt(value / 10);
            if (remainder) items += 1;
            setTotalList(items);
        }
        else {
            setTotalList(1);
        }
    }

    const handleClick = (e) => {
        if (e.target.textContent === '⟩' && page < totalList) {
            getPageData(page + 1)
        }
        else if (e.target.textContent === '⟨' && page > 1) {
            ;
            getPageData(page - 1)
        }
        else if (parseInt(e.target.textContent)) {
            getPageData(parseInt(e.target.textContent))
        }
    }

    useEffect(() => {
        if (totalData > 0) {
            customLogic(totalData);
        }
    }, [totalData]);
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "25px", marginTop: "20px" }}>
            <Pagination
                boundaryRange={1}
                defaultActivePage={page}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                totalPages={totalList}
                style={{ height: "10px", marginRight: 5, border: "1px solid orange", paddingTop: "5px" }} onClick={(e) => handleClick(e)}
            />
            <div style={{ padding: 5, height: "40px", marginLeft: 5, border: "1px solid orange", display: "flex", borderRadius: 4 }}>
                <span style={{ margin: "auto" }}>Total Data: {totalData}</span>
            </div>
        </div>
    )
}

export default PaginationData