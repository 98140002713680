import React, { useEffect, useState } from 'react'
import { getListData } from '../services/commonServices'
import { Table, Spinner, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment'
import Model from './Model';
import PaginationData from './Pagination';
import { Link } from 'react-router-dom';

function PhysicianGroups() {
  const [physicianGroups, setphysicianGroups] = useState([]);
  const [showLoaderPg, setShowLoaderPg] = useState(true);
  const [showModelPg, setShowModelPg] = useState(false);
  const [searchValuePg, setSearchValuePg] = useState("");
  const [selectedDataPg, setSelectedDataPg] = useState("");
  const [pagePg, setPagePg] = useState(1);
  const [totalDataPg, setTotalDataPg] = useState(10);
  const headersPg = ["Sr No", "Profile Pic", "Name", "Phone No.", "Email Id", "Registration Date", "Active Applications", "Actions"];
  const getListPg = async () => {
    setShowLoaderPg(true);
    setphysicianGroups([]);
    let dataPg = await getListData("physicianGroup", pagePg, searchValuePg);
    setphysicianGroups([...dataPg.data[0].data]);
    if (dataPg.data[0].metadata.length > 0) setTotalDataPg(dataPg.data[0].metadata[0].total)

    setShowLoaderPg(false)
  }
  useEffect(() => {
    getListPg()
  }, []);

  const getSearchDataPg = async (e) => {
    setphysicianGroups([]);
    setSearchValuePg(e.target.value)
    setShowLoaderPg(true)
    let data = await getListData("physicianGroup", pagePg, e.target.value);
    setphysicianGroups([...data.data[0].data]);
    setShowLoaderPg(false)
  }

  // Function for changing the status
  const handleChangeStatusPg = (status) => {
    setShowModelPg(status);
    getListPg()
  }

  const handleSelectPg = (data) => {
    setSelectedDataPg(data._id)
    setShowModelPg(true);
  }

  const handlePaginationPg = async (pagePgNo) => {
    setPagePg(pagePgNo);
    setphysicianGroups([]);
    setShowLoaderPg(true)
    let dataPg = await getListData("physicianGroup", pagePgNo, searchValuePg);
    setphysicianGroups([...dataPg.data[0].data]);
    setShowLoaderPg(false)
  }
  return (
    <div>
      <div className='upperHeader'>
        <div><h3 style={{ textAlign: "center" }}>Physician Groups</h3></div>
        {<div>
          <Form className="d-flex align-content-center">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              value={searchValuePg}
              onChange={getSearchDataPg}
              style={{ width: "250px" }}
            />
          </Form>
        </div>}
      </div>
      <div>
        {physicianGroups.length > 0 ?
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                {headersPg.map((data, index) => (
                  <th key={index} className={data === "Actions" ? 'text-center' : ''}>{data}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {physicianGroups.length > 0 && physicianGroups.map((dataPg, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <Link to={`/view-detail?type=physicianGroup&id=${dataPg._id}`} style={{ color: "black" }}>
                      <div className='image-div'>
                        <img src={dataPg.profileImage} alt='img' />
                      </div>
                    </Link>
                  </td>
                  <td>
                    <Link to={`/view-detail?type=physicianGroup&id=${dataPg._id}`} style={{ color: "black" }}>
                      {dataPg.name}
                    </Link>
                  </td>
                  <td>{dataPg.phoneNumber}</td>
                  <td>{dataPg.email ? dataPg.email : "N/A"}</td>
                  <td>{moment(dataPg.createdAt).format("LL")}</td>
                  <td className='text-center'>{dataPg.totalNoApplications ? dataPg.totalNoApplications : "0"}</td>
                  <td className='text-center'>
                    <span title={dataPg.status === "Active" ? "Block" : "Unblock"}>
                      <FontAwesomeIcon icon={faBan} style={{ color: dataPg.status === "Active" ? "green" : "red", fontSize: "1.5em", marginLeft: "8%", cursor: "pointer" }} onClick={() => handleSelectPg(dataPg)} />
                      {selectedDataPg && selectedDataPg === dataPg._id && <Model openModel={showModelPg} changeValue={handleChangeStatusPg} id={dataPg._id} status={dataPg.status} parent={'physicianGroup'} />}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table> :
          (!showLoaderPg && <div style={{ marginLeft: "40%", marginTop: "100px" }}>
            <h3>No Data Available</h3>
          </div>)}
        {showLoaderPg && <Spinner animation="border" style={{ marginLeft: "50%", marginTop: "5%", width: "4rem", height: "4rem" }} size='lg' />}
        {physicianGroups.length > 0 && <PaginationData page={pagePg} totalData={totalDataPg} getPageData={handlePaginationPg} />}
      </div>
    </div>
  )
}

export default PhysicianGroups